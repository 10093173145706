import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "formSection", "oauthSection"];

  connect() {
    console.log("FormController connected");
  }

  submitForm(event) {
    if (!this.formTarget.checkValidity()) {
      return;
    }

    event.preventDefault();

    // Hide the form section with Tailwind classes
    this.formSectionTarget.classList.remove('opacity-100');
    this.formSectionTarget.classList.add('opacity-0');


    setTimeout(() => {
      this.formSectionTarget.classList.add("hidden");

      // Show the OAuth section
      this.oauthSectionTarget.classList.remove("hidden");
      this.oauthSectionTarget.offsetHeight; // Trigger reflow
      this.oauthSectionTarget.classList.add('opacity-100');
      this.oauthSectionTarget.classList.remove('opacity-0');
    }, 500)

  }
}
